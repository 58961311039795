

import axios from "axios";
import { defineComponent, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { Reading } from "@/core/config/tables";
import config from "@/core/config/UtilConfig";
import supplyExternal from "../scripts/supplyExternal.js";
import ReadingModal from "@/components/modals/forms/ReadingModal.vue";
import { useI18n } from "vue-i18n";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "readings-listing",
  components: {
    Datatable,
    ReadingModal
  },
  setup() {
    const util = config.util;
    const { t, te } = useI18n();
    
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(translate("lastReadings"), []);
    });

    return { 
      translate,
      util
    };
  },
  data(){
    return {
      readings: Array<Reading>(),
      key: "init",
      headers: config.readingHeaders,
      page: 1,
      pageSize: 20,
      field: config.readingSort,
      order: "desc",
      refreshing: false,
      totalCount: 0
    }
  },
  beforeMount(){
    this.getReadings(this.field, this.order);
  },
  mounted(){
    window.addEventListener("locale-changed", (event) => {
      setCurrentPageBreadcrumbs(this.translate("lastReadings"), []);
      this.key = "lang"+localStorage.getItem("lang");
    });
    this.getNextReadings();
    window.setInterval(() => {
      if(document.getElementsByClassName("modal-backdrop")[0]){
        document.getElementsByClassName("modal-backdrop")[0].classList.remove("show");
      } 
      this.refreshReadings();
    }, config.refresh)
  },
  methods: {
    getReadings: async function(field, order){
      this.field = field;
      this.order = order;
      this.page = 1; 
      supplyExternal.methods.getIP(function(ip){return})
      setTimeout(() => {
        const params = { params: {ip: store.getters.getIP, util: config.util, page: this.page, pageSize: this.pageSize, sort: this.field+":"+this.order} };
        axios.get(config.host+"/o/headless-supply-box/v1.0/readings", params).
          then(response => {
            if(response.data.items[0]) {
              this.readings = response.data.items;
              this.page += 1;
              this.key = "reloadReadings"+this.field+this.order;
              if(config.util === "SLMUtil" && response.data.totalCount > this.totalCount) {
                Swal.fire({
                  text: "Nuevas lecturas",
                  icon: "info",
                  iconColor: "green",
                  showConfirmButton: false,
                  timer: 2000
                });
              }
              this.totalCount = response.data.totalCount;
            }
            else {
              this.readings = Array<Reading>();
              this.key = "empty";
            }
            this.refreshing = false;
          }).catch(e => {
            this.readings = Array<Reading>();
            this.key = "empty";
            this.refreshing = false;
          });
      }, 500);
    },
    getNextReadings: function(){
      window.onscroll = () => {

        let bottomOfWindow = (window.innerHeight + document.documentElement.scrollTop) === document.documentElement.scrollHeight;

        if (bottomOfWindow) {
          supplyExternal.methods.getIP(function(ip){return})
          setTimeout(() => {
            const params = { params: {ip: store.getters.getIP, util: config.util, page: this.page, pageSize: this.pageSize, sort: this.field+":"+this.order} };
            axios.get(config.host+"/o/headless-supply-box/v1.0/readings", params).
              then(response => {
                for(var i= 0; i<response.data.items.length; i++) {
                  this.readings.push(response.data.items[i]);
                }
                this.page += 1;
                this.key = "nextReadings"+this.page;
              }).catch(e => console.log(e));
          }, 500);
        }
      }
    },
    refreshReadings: function(){
      this.refreshing = true;
      this.getReadings(this.field, this.order);
      this.key = "refreshReadings"+this.field+this.order;
    },
    setCurrentElement: async function(reading) {
      if(config.util === "SBUtil") {
        store.dispatch(Actions.UPDATE_READING, reading);
      
        this.key="updateReading"+reading.id
        setTimeout(() => { document.getElementById("viewReadingModal")?.click(); }, 500);
      }
    },
    toggleOrder: function(id, doubleOrder) {
      axios.patch(config.host+"/o/headless-supply-box/v1.0/readings/"+id, {doubleOrder: !doubleOrder}, {params: {ip: store.getters.getIP, util: config.util}});
      this.getReadings(this.field, this.order);
      this.key = "toggleOrder"+id+doubleOrder;
    }
  }
});
